import * as yup from 'yup';

import { GalleryItem } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type UpdateParams = Omit<Partial<GalleryItem.Model>, 'mediaDeskUrl' | 'mediaMobUrl'> & {
  id?: GalleryItem.Model['id'];
  mediaDesk?: File;
  mediaMob?: File;
};
export type UpdateResult = { id: GalleryItem.Model['id'] };
export type UpdateError = EndpointError;

export const updateGalleryItemParamsSchema = yup.object().shape({
  id: yup.string(),
  title: yup.string().max(32, VALIDATION_ERROR.TOO_LONG).required(VALIDATION_ERROR.REQUIRED),
  subTitle: yup.string().max(35, VALIDATION_ERROR.TOO_LONG).required(VALIDATION_ERROR.REQUIRED),
  link: yup.string().required(VALIDATION_ERROR.REQUIRED),
  mediaDeskUrl: yup.string(),
  mediaDesk: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) =>
      value && typeof value === 'object'
        ? ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'video/mp4'].includes(value.mimetype || value.type)
        : true,
    )
    .required(VALIDATION_ERROR.REQUIRED),
  mediaMobUrl: yup.string(),
  mediaMob: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) =>
      value && typeof value === 'object'
        ? ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'video/mp4'].includes(value.mimetype || value.type)
        : true,
    )
    .required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/gallery-item/item`,
});
