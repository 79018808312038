import * as yup from 'yup';

import { GalleryItem } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type DeleteParams = { id: GalleryItem.Model['id'] };
export type DeleteResult = { id: GalleryItem.Model['id'] };
export type DeleteError = EndpointError;

export const deleteGalleryItemParamsSchema = yup.object().shape({
  id: yup.string().required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<DeleteParams>);

export const deleteItem = endpoint<DeleteParams, DeleteResult, DeleteError>({
  method: 'DELETE',
  url: () => `/gallery-item/item`,
});
