import { GalleryItem } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';

export type GetParams = void;
export type GetResult = {
  items: GalleryItem.Model[];
};
export type GetError = EndpointError;

export const get = endpoint<GetParams, GetResult, GetError>({
  method: 'GET',
  url: () => `/gallery-item`,
});
