import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model as GalleryItemModel } from 'models/galleryItem';
import * as actions from './actions';

export interface GalleryItems {
  items: Array<GalleryItemModel>;
}

const initialState: GalleryItems = {
  items: [],
};

export const admin = createSlice({
  name: 'galleryItems',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.items = action.payload;
    },
  },
});

export const { init } = admin.actions;
export default admin.reducer;
