import * as yup from 'yup';

import { GalleryItem } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type UpdateOrderParams = {
  order: GalleryItem.Model['id'][];
};
export type UpdateOrderResult = { orderUpdated: true };
export type UpdateOrderError = EndpointError;

export const updateOrderParamsSchema = yup.object().shape({
  order: yup.array(yup.string().required(VALIDATION_ERROR.REQUIRED)).required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<UpdateOrderParams>);

export const updateOrder = endpoint<UpdateOrderParams, UpdateOrderResult, UpdateOrderError>({
  method: 'POST',
  url: () => `/gallery-item/update-order`,
});
