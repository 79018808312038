/* eslint-disable no-useless-return */
// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { useResponsive } from 'client/hooks';

import { share } from 'client/utils/share';
import { dataLayerEvents } from 'src/routes/client/utils/gtag';
import { SHARE_CONSTANTS, IS_DEV } from 'src/constants';
import Icon from '../Icon';

import css from './Socialls.module.scss';

interface Props {
  className?: '';
}

const Socialls: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [isShowCopyText, setIsShowCopyText] = useState<boolean>(false);
  const copyUrl = 'https://www.ynet.co.il/economy/category/42412';
  const [isDesktop] = useResponsive('DESKTOP');
  const onShare = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const type = e.currentTarget.dataset.id;

    if (!IS_DEV) {
      if (type) {
        dataLayerEvents.onShareBtnClick(type);
      }
      share(type, isDesktop);
    }
  };

  const onCopyClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const { copy } = e.currentTarget.dataset;
    const type = e.currentTarget.dataset.id;
    if (type) {
      dataLayerEvents.onShareBtnClick(type);
    }
    if (document && document.body) {
      const el = document.createElement('textarea');
      // eslint-disable-next-line max-len
      el.value = `${copy}`;
      // flow-disable-next-line
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      // flow-disable-next-line
      document.body.removeChild(el);
    }

    setIsShowCopyText(true);

    setTimeout(() => {
      setIsShowCopyText(false);
    }, 2000);
  };

  return (
    <div className={classNames(css.socialls, className)}>
      <button
        className={classNames(css.button, css.socialButton)}
        type="button"
        data-id={SHARE_CONSTANTS.FACEBOOK}
        onClick={onShare}
      >
        <Icon type="facebook" width={27.999} height={27.667} className={css.iconFacebook} />
      </button>
      <button
        type="button"
        className={classNames(css.button, css.socialButton)}
        data-id={SHARE_CONSTANTS.WHATSAPP}
        onClick={onShare}
      >
        <Icon type="whatsapp" width={27.999} height={27.667} className={css.iconWhatsapp} />
      </button>
      <button
        type="button"
        className={classNames(css.button, css.copyButton)}
        onClick={onCopyClick}
        data-copy={copyUrl}
        data-id={SHARE_CONSTANTS.COPY}
      >
        <Icon type="copy" width={27.999} height={27.667} className={css.iconCopy} />
        <p className={classNames(css.copyText, isShowCopyText && css.show)}>הקישור הועתק</p>
      </button>
    </div>
  );
};

export default Socialls;
