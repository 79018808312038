import * as React from 'react';
import cn from 'classnames';

import { useResponsive } from 'client/hooks';
import { Model as GalleryItemModel } from 'models/galleryItem';
import { dataLayerEvents } from 'src/routes/client/utils/gtag';
import css from './Gallery.module.scss';

interface Props {
  items: Array<GalleryItemModel>;
}

const Gallery: React.FC<Props> = (props) => {
  const { items } = props;
  const [isMobile] = useResponsive('MOBILE');

  const onItemClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const title = e.currentTarget.dataset.title;

    if (title) {
      dataLayerEvents.onArticleClick(title);
    }
  };

  return (
    <section className={cn(css.galleryWrapper)}>
      <div className={css.top}>
        <div className={css.textBlock}>המוצרים והשירותים שהכי אהבתם השנה</div>
        <div className={css.colorBlock}></div>
      </div>
      <div className={css.items}>
        {items.map((item) => {
          const backgroundImage = () => {
            if (isMobile && item.mediaMobUrl) {
              return item.mediaMobUrl;
            }
            if (!isMobile && item.mediaDeskUrl) {
              return item.mediaDeskUrl;
            }

            return null;
          };
          return (
            <div className={css.item} key={item.id} data-title={item.title} onClick={onItemClick}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <div
                  className={css.image}
                  style={backgroundImage() ? { backgroundImage: `url("${backgroundImage()}")` } : {}}
                ></div>
                <div className={css.info}>
                  <div className={css.title}>{item.title}</div>
                  <div className={css.subTitle}>{item.subTitle}</div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Gallery;
