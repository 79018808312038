import React from 'react';
import cn from 'classnames';

import { useAppSelector } from 'client/hooks';
import { selectGalleryItmes } from 'client/redux/galleryItems/selectors';
import { Header, Gallery } from 'client/components/common';
import css from './Main.module.scss';
import login from 'client/redux/login';

interface Props {
  className?: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const items = useAppSelector(selectGalleryItmes);

  const appRef = React.useRef<HTMLElement | null>(null);

  const observer = React.useCallback(() => {
    const height = appRef.current?.offsetHeight || 0;

    const parentHeight = window.innerHeight;
    if (height !== parentHeight && Math.abs(height - parentHeight) > 5) {
      window.parent.postMessage(
        {
          payload: { iframeHeight: height },
          sender: 'BRAVO_WIDGET',
          type: 'resize',
        },
        '*',
      );
    }
    requestAnimationFrame(observer);
  }, []);

  React.useEffect(() => {
    if (!appRef.current) {
      appRef.current = document.getElementById('root');
    }
    const rafId = requestAnimationFrame(observer);

    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [observer]);

  return (
    <div className={cn(css.main, className)}>
      <Header />
      <Gallery items={items} />
    </div>
  );
};

export default Main;
