import * as React from 'react';
import cn from 'classnames';
import { useResponsive } from 'client/hooks';
import headerLogo from 'src/routes/client/assets/header-logo-2024.jpg';
import Socialls from '../Socialls';
import Accessibility from '../Accessibility';
import css from './Header.module.scss';

interface Props {
  className?: '';
}

const Header: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [isMobile] = useResponsive('MOBILE');

  return (
    <header className={cn(css.header, className)}>
      <div className={css.sociallsWrapper}>
        <Socialls />
        <Accessibility />
      </div>

      {!isMobile && (
        <div className={css.headerLogoDesk}>
          <img src={headerLogo} alt="logo" />
        </div>
      )}
      {isMobile && (
        <div className={css.headerLogoMob}>
          <img src={headerLogo} alt="logo" />
        </div>
      )}
    </header>
  );
};

export default Header;
