import { createSelector } from 'reselect';

import { RootState } from '..';

export const selectItems = (state: RootState) => {
  return state.galleryItems.items;
};

export const selectGalleryItmes = createSelector(selectItems, (items) => {
  return items;
});
