// @flow

import * as React from 'react';
import _ from 'lodash';
import { useResponsive } from 'client/hooks';
import { detectIE } from 'src/utils/detect-ie';
import { CAN_USE_DOM } from 'client/constants';

import Icon from '../Icon';

import css from './Accessibility.module.scss';

interface Props {
  onClick?: (event: any) => void;
}

const AccessibilityIcon: React.FC<Props> = (props) => {
  const [isDesktop] = useResponsive('DESKTOP');
  // eslint-disable-next-line class-methods-use-this
  const accessablityBtn = () => {
    if (!CAN_USE_DOM) {
      return null;
    }

    return document.querySelector('#INDmenu-btn');
  };

  const onClick = (event: React.SyntheticEvent) => {
    const { onClick } = props;
    const btn = accessablityBtn();
    if (btn) {
      const eventClick = new Event('click');
      btn.dispatchEvent(eventClick);
    }
    if (onClick) {
      onClick(event);
    }
  };

  const onMouseEnter = () => {
    let eventFocus;
    const btn = accessablityBtn();
    if (!CAN_USE_DOM) {
      return;
    }

    if (isDesktop) {
      if (detectIE() > 0) {
        eventFocus = document.createEvent('Event');
        eventFocus.initEvent('focus', false, true);
      } else {
        eventFocus = new Event('focus');
      }

      if (btn) {
        btn.dispatchEvent(eventFocus);
      }
    }
  };

  const onMouseLeave = () => {
    let eventBlur;
    const btn = accessablityBtn();

    if (!CAN_USE_DOM) {
      return;
    }

    if (detectIE() > 0) {
      eventBlur = document.createEvent('Event');
      eventBlur.initEvent('blur', false, true);
    } else {
      eventBlur = new Event('blur');
    }

    if (btn) {
      btn.dispatchEvent(eventBlur);
    }
  };

  return (
    <button
      type="button"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={css.button}
    >
      <Icon type="accessibility" width={27.999} height={27.667} />
    </button>
  );
};

export default AccessibilityIcon;
