import { CONFIG, CAN_USE_DOM, SHARE_CONSTANTS } from 'src/constants';

export const share = (type: string | undefined, isDesktop: boolean | undefined) => {
  if (!CAN_USE_DOM) {
    return;
  }

  const domain = 'https://www.ynet.co.il/economy/category/42412';
  const encode = encodeURIComponent;
  const title = 'המצטיינים של 2024';
  const description = 'קראו כאן מיהם המוצרים ונותני השירותים שהצרכנים הישראלים ממליצים לכם עליהם';
  // const domain = `${CONFIG.api.split('/api')[0]}`;
  // const titleProp = CAN_USE_DOM ? document.querySelector('meta[property="og:title"]') : null;
  // const title: string | null = titleProp ? titleProp.getAttribute('content') : '';
  // const descriptionProp = CAN_USE_DOM ? document.querySelector('meta[property="og:description"]') : null;
  // const description: string | null = descriptionProp ? descriptionProp.getAttribute('content') : '';
  // const imageProp = CAN_USE_DOM ? document.querySelector('meta[property="og:image"]') : null;
  // const image: string | null = imageProp ? imageProp.getAttribute('content') : '';
  // const encode = encodeURIComponent;
  // const text = title && description ? `${title}\n${description}` : '';

  if (type === SHARE_CONSTANTS.FACEBOOK) {
    const fbURL = 'https://www.facebook.com/sharer.php?u=';

    window.open(`${fbURL}${domain}`, 'sharer', 'width=555,height=600');
  }

  if (type === SHARE_CONSTANTS.WHATSAPP) {
    if (title && description) {
      if (isDesktop) {
        window.open(
          `https://web.whatsapp.com/send?text=${encode(title + '\n' + description + '\n' + domain)}`,
          '_blank',
        );
      } else {
        window.location.href = 'whatsapp://send?text=' + encode(title + '\n' + description + '\n' + domain);
      }
    }
  }

  // if (type === SHARE_CONSTANTS.TWITTER) {
  //   const twURL = 'https://twitter.com/intent/tweet?url=';
  //   const finalURL = `${twURL}${domain}&text=${encode(`${text}\n`)}`;

  //   const shareWindow = window.open(finalURL, 'ShareOnTwitter', 'width=555,height=600');

  //   if (shareWindow) {
  //     shareWindow.opener = null;
  //   }
  // }
};
