import { CAN_USE_DOM } from '../constants';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const dataLayerEvents = {
  onShareBtnClick: (label: string) =>
    CAN_USE_DOM &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'GA_event',
      Category: 'Bravo 2024 iFrame',
      Action: 'Share From Header',
      Label: label,
    }),
  onArticleClick: (label: string) =>
    CAN_USE_DOM &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'GA_event',
      Category: 'Bravo 2024 iFrame',
      Action: 'Navigation',
      Label: label,
    }),
};
