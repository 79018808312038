export enum CLIENT_ROLE {
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
  ARTIST = 'ARTIST',
}

export const ANONYMOUS_UID = 'anonymous';

export const SHARE_CONSTANTS = {
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  COPY: 'Copy',
};

export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const ERROR = {
  REQUIRED: 'Required',
  TOO_SHORT: 'Too short',
  TOO_LONG: 'Too long',
  TOO_WEAK: 'Too weak',
  INVALID_FORMAT: 'Invalid format',
  INVALID_VALUE: 'Invalid value',
};

export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
