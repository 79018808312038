export const CONFIG = JSON.parse(process.env.CONFIG || '{}');
export const REDUX_STORAGE_KEY = '__REDUX_STORAGE__';
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const IS_DEV = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
  WHATSAPP: 'WhatsApp',
  TWITTER: 'Twitter',
  FACEBOOK: 'Facebook',
  COPY: 'Copy',
};
