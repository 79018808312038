export enum ADMIN_ROLE {
  CONTENT_VIEWER = 'CONTENT_VIEWER',
  CONTENT_EDITOR = 'CONTENT_EDITOR',
  CONTENT_ADMIN = 'CONTENT_ADMIN',
  PRODUCT_ADMIN = 'PRODUCT_ADMIN',
  DEVELOPER = 'DEVELOPER',
}

export const SCOPE = {
  CONTENT: {
    VIEW: 'CONTENT.VIEW',
    EDIT: 'CONTENT.EDIT',
    CREATE: 'CONTENT.CREATE',
    DELETE: 'CONTENT.DELETE',
  },
  USERS: {
    VIEW: 'USERS.VIEW',
    EDIT: 'USERS.EDIT',
    CREATE: 'USERS.CREATE',
    DELETE: 'USERS.DELETE',
  },
} as const;

export const PERMISSIONS = {
  [ADMIN_ROLE.CONTENT_VIEWER]: [SCOPE.CONTENT.VIEW],
  [ADMIN_ROLE.CONTENT_EDITOR]: [SCOPE.CONTENT.VIEW, SCOPE.CONTENT.EDIT],
  [ADMIN_ROLE.CONTENT_ADMIN]: Object.values(SCOPE.CONTENT),
  [ADMIN_ROLE.PRODUCT_ADMIN]: [...Object.values(SCOPE.CONTENT), ...Object.values(SCOPE.USERS)],
  [ADMIN_ROLE.DEVELOPER]: [...Object.values(SCOPE.CONTENT), ...Object.values(SCOPE.USERS)],
};

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}
