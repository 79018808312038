import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import 'client/styles/index.scss';

import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import { ANONYMOUS_UID, CLIENT_ROLE } from 'client/constants';
import { useAppDispatch, useAppSelector, useResponsive } from 'client/hooks';
import { checkRedirectSignIn, signIn } from 'client/redux/client/actions';

import Main from './pages/Main';
import favicon from '../assets/favicon/favicon.png';
import share from '../assets/share.png';
import { selectUserData } from '../redux/client/selectors';

import css from './App.module.scss';

const App: React.FC = () => {
  const client = useAppSelector(selectUserData);
  const [isMatchingMQ] = useResponsive(['MOBILE', 'TABLET', 'TABLET_L', 'TABLET_P']);
  const appDispatch = useAppDispatch();

  React.useEffect(() => {
    const checkSignIn = async () => {
      if (isMatchingMQ === undefined) return;

      const isCheckRedirectSignInRequired = window.localStorage.getItem('isCheckRedirectSignInRequired') === 'true';
      let isSignedInByRedirect = false;

      if (isCheckRedirectSignInRequired && isMatchingMQ && (!client.uid || client.uid.includes(ANONYMOUS_UID))) {
        console.log('checkRedirectSignIn()');
        isSignedInByRedirect = !!(await appDispatch(checkRedirectSignIn())).payload;
        window.localStorage.setItem('isCheckRedirectSignInRequired', 'false');
      }

      if (!isSignedInByRedirect && !client.uid) {
        console.log('Client is unauthorized...');
        appDispatch(signIn({ uid: ANONYMOUS_UID, role: CLIENT_ROLE.USER }));
      }
    };

    checkSignIn();
  }, [appDispatch, client.uid, isMatchingMQ]);

  const renderHelmet = () => {
    const seo = {
      title: 'המצטיינים של 2024',
      description: 'קראו כאן מיהם המוצרים ונותני השירותים שהצרכנים הישראלים ממליצים לכם עליהם',
    };
    const og = {
      title: 'המצטיינים של 2024',
      description: 'קראו כאן מיהם המוצרים ונותני השירותים שהצרכנים הישראלים ממליצים לכם עליהם',
      image: share,
    };

    return (
      <Helmet>
        <title>{seo.title}</title>
        <link rel="icon" type="image/png" href={favicon} />
        <link rel="canonical" href="https://www.ynet.co.il/economy/category/42412" />
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={og.title} />
        <meta property="og:description" content={og.description} />
        <meta property="og:image" content="https://ynet-bravo.firebaseapp.com/assets/share.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ynet.co.il/economy/category/42412" />
      </Helmet>
    );
  };

  return (
    <div className={css.app}>
      {renderHelmet()}
      <Switch>
        <Route exact path={MAIN_PAGE_ROUTE}>
          <Main />
        </Route>
        <Redirect to={MAIN_PAGE_ROUTE} />
      </Switch>
    </div>
  );
};

export default App;
